export interface IProductAttributeAllTypes {
  manufacturers: IProductAttribute[];
  strengths: IProductAttribute[];
  origins: IProductAttribute[];
  wrappers: IProductAttribute[];
  binders: IProductAttribute[];
  fillers: IProductAttribute[];
  wrapperColors: IProductAttribute[];
}

export interface IProductAttribute {
  Id: number;
  AttributeId: number;
  Value: string;
}

export enum IProductAttributeTypeIds {
  Manufacturers = 1818,
  Strengths = 1810,
  Origins = 1809,
  Wrappers = 1812,
  Binders = 1813,
  Fillers = 1814,
  RollingTypes = 1815,
  WrapperColors = 1811,
  CARatings = 1903,
  CIRatings = 1905,
}
