import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

import { CigarTypes, ICigar, ITopRatedCigar, ITopScannedCigar } from '@models';
import {
  IProductAttribute,
  IProductAttributeAllTypes,
  IProductAttributeTypeIds,
} from '@models/attributes';
import { ProductLineModel } from '@shared/models/product-line.model';
import { ProductSizeModel } from '@shared/models/product-size.model';
import { TopCigarModel } from '@shared/models/top-cigar.model';
import { Observable, forkJoin, map } from 'rxjs';
import queryString from 'query-string';

export const PRODUCT_TYPE = 'products';
export const LINE_TYPE = 'lines';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private http: HttpClient) {}

  getProduct(productId: number) {
    return this.http.get<ICigar>(`cigars/products/${productId}`);
  }

  getLine(LineId: number) {
    return this.http.get<ICigar>(`cigars/lines/${LineId}`);
  }

  getList(type: CigarTypes, ids: number[]) {
    const key = type === CigarTypes.LINES ? 'LineIds' : 'ProductIds';
    const queryParams = ids.map((id) => `${key}=${id}`).join('&');

    return this.http.get<ICigar[]>(`cigars/${type}?${queryParams}`);
  }

  getSizes(lineId: number): Observable<ProductSizeModel[]> {
    return this.http.get<ProductSizeModel[]>(
      `cigars/lines/${lineId}/products/info`
    );
  }

  getAttributeValues(attributeId: number) {
    return this.http.get<IProductAttribute[]>(
      `attributes/${attributeId}/values`
    );
  }

  getAllProductAttributeValues(): Observable<IProductAttributeAllTypes> {
    return forkJoin([
      this.getAttributeValues(IProductAttributeTypeIds.Manufacturers),
      this.getAttributeValues(IProductAttributeTypeIds.Strengths),
      this.getAttributeValues(IProductAttributeTypeIds.Origins),
      this.getAttributeValues(IProductAttributeTypeIds.Wrappers),
      this.getAttributeValues(IProductAttributeTypeIds.Binders),
      this.getAttributeValues(IProductAttributeTypeIds.Fillers),
      this.getAttributeValues(IProductAttributeTypeIds.WrapperColors),
    ]).pipe(
      map((results) => ({
        manufacturers: results[0],
        strengths: results[1],
        origins: results[2],
        wrappers: results[3],
        binders: results[4],
        fillers: results[5],
        wrapperColors: results[6],
      }))
    );
  }

  // createCustom(customProduct: ManualCigarEntryModel) {
  //   return this.http
  //     .post('cigars/custom/product', customProduct)
  //     .pipe(map((res) => new ProductModel(res)));
  // }

  // search(filter: ProductSearchModel, firstLoad = false) {
  //   const sameFilter = isEqual(this.filter, filter);

  //   if (this.cigars && sameFilter && (firstLoad || !this.showMore)) {
  //     return of(this.cigars);
  //   }

  //   if (!sameFilter) {
  //     this.filter = cloneDeep(filter);
  //     this.cigars = null;
  //   }

  //   let params = new HttpParams();
  //   params = params.set(
  //     'Skip',
  //     this.cigars ? this.cigars.length.toString() : '0'
  //   );
  //   params = params.set('Take', this.take.toString());

  //   return this.http
  //     .post<CigarLightModel[]>(`cigars/powersearch`, this.filter, { params })
  //     .pipe(
  //       map((cigars) => {
  //         this.moreToShow.next(cigars.length === this.take);
  //         this.cigars = this.cigars
  //           ? this.cigars.concat(this._mapCollection(ProductModel, cigars))
  //           : this._mapCollection(ProductModel, cigars);
  //         return this.cigars;
  //       })
  //     );
  // }

  // clearSearchResults() {
  //   this.cigars = null;
  //   this.filter = new ProductSearchModel();
  //   this.moreToShow.next(false);
  // }

  searchLine(
    searchText: string,
    skip: number,
    take: number = environment.pageLimit
  ): Observable<ProductLineModel[]> {
    return this.http.post<ProductLineModel[]>('cigars/lines/search', {
      SearchString: searchText,
      Skip: skip,
      Take: take,
    });
  }

  getTopRated(params: {
    strength: '' | 'Mild' | 'Medium' | 'Full';
    days?: number;
  }) {
    return this.http.get<ITopRatedCigar[]>(
      `cigars/toprated?${queryString.stringify(params)}`
    );
  }

  getTopScanned(params: {
    strength: '' | 'Mild' | 'Medium' | 'Full';
    days?: number;
  }) {
    return this.http.get<ITopScannedCigar[]>(
      `cigars/topscanned?${queryString.stringify(params)}`
    );
  }

  // saveNote(product: ProductModel): Observable<ProductModel> {
  //   const params = ProductModel.decomposeId(product.Id);

  //   return this.http
  //     .post(
  //       `cigars/${params.type}/${params.id}/note`,
  //       this._prepareStringAsPayload(product.MyNote.Note),
  //       {
  //         headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  //       }
  //     )
  //     .pipe(
  //       map((res) => {
  //         product.MyNote = new ProductNoteModel(res);
  //         return product;
  //       })
  //     );
  // }

  // saveFeatures(product: ProductModel): Observable<ProductModel> {
  //   const params = ProductModel.decomposeId(product.Id);

  //   return this.http
  //     .post(
  //       `cigars/${params.type}/${params.id}/features`,
  //       product.MyCigarFeatures
  //     )
  //     .pipe(
  //       map((res) => {
  //         product.MyCigarFeatures = new ProductMyCigarFeaturesModel(res);
  //         return product;
  //       })
  //     );
  // }

  // getFilter() {
  //   return cloneDeep(this.filter);
  // }

  getIdByUpc(upc: string) {
    return this.http.get<string>(`cigars/products/byupc/${upc}`);
  }
}
